<template>
  <v-container pa-5 fluid>
    <h2 class="mb-5">Categorias</h2>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>Categorias</v-toolbar-title>
        <v-divider class="mx-2" inset vertical />
        <v-text-field
          v-model="search"
          hide-details
          placeholder="Descrição"
          solo
          dense
          clearable
        />
        <v-spacer />
        <v-btn color="primary" class="mb-2" outlined @click="novaCategoria">
          Nova Categoria
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="categorias"
        :loading="loading"
        :search="search"
        class="elevation-1"
        loading-text="Carregando..."
        hide-default-footer
      >
        <template slot="headerCell" slot-scope="{ header }">
          <span
            class="subheading font-weight-light text--darken-3"
            v-text="header.text"
          />
        </template>
        <template v-slot:body="{ items }" ma-5>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>
                <v-chip class="pl-10 pr-10" :color="item.color" label></v-chip>
              </td>
              <td>{{ item.descricao }}</td>
              <td class="text-right">
                <v-icon
                  class="mr-2"
                  title="Editar categoria"
                  @click="editarCategoria(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  color="primary"
                  title="Excluir categoria"
                  @click="openDialogDelete(item)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <template v-if="dialogDelete">
      <v-row justify="center">
        <v-dialog v-model="dialogDelete" persistent max-width="500px">
          <v-card>
            <v-form
              ref="formDelete"
              v-model="valid"
              lazy-validation
              @submit.prevent="deleteCategoria"
            >
              <v-card-title>
                <span class="headline">Excluir Categoria</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col align="center" cols="12">
                      <span class="title">
                        Deseja realmente excluir essa categoria?
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="categoriaExcluir"
                        label="Nome da categoria que será excluída*"
                        :rules="[rules.required]"
                        :hint="categoria.descricao"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="btnCancelar"
                  text
                  @click="() => (dialogDelete = false)"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  type="submit"
                  :loading="loadingSalvar"
                >
                  <span style="color: white;">Excluir</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template v-if="dialog">
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="500px">
          <v-card>
            <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
              <v-toolbar-title>
                <span class="headline">{{ formTitle }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon large @click="dialog = false">mdi-close</v-icon>
            </v-toolbar>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="salvarCategoria"
            >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="categoria.descricao"
                        label="Descrição*"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-color-picker
                        v-model="categoria.color"
                        hide-canvas
                        hide-inputs
                        hide-mode-switch
                        hide-sliders
                        mode="hexa"
                        show-swatches
                        swatches-max-height="100"
                        width="100vw"
                      ></v-color-picker>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="btnCancelar" text @click="dialog = false">
                  Cancelar
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  type="submit"
                  :loading="loadingSalvar"
                >
                  <span style="color: white;">Salvar</span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { CategoriaService } from "../../services";
import { Role } from "@/constants";

export default {
  data: () => ({
    valid: false,
    search: "",
    categorias: [],
    dialog: false,
    dialogSenha: false,
    dialogDelete: false,
    filtros: {
      descricao: ""
    },
    options: {},
    headers: [
      { text: "Cor", value: "color", width: 15, sortable: false },
      { text: "Descrição", value: "descricao" },
      { text: "Ações", value: "", align: "right", sortable: false }
    ],
    editedIndex: -1,
    categoria: {
      descricao: "",
      color: ""
    },
    rules: {
      required: value => !!value || "Campo obrigatório.",
      confirmDelete: value =>
        (!!value && this.categoria.descricao === value) ||
        "A categoria precisa ser igual!"
    },
    loadingSalvar: false,
    loading: false,
    loadingPesquisar: false,
    categoriaExcluir: ""
  }),
  computed: {
    formTitle() {
      return this.categoria.id ? "Alterar Categoria" : "Nova Categoria";
    }
  },
  created(){
    if(this.isMobile){
      this.$router.push('/check-in')
    }
    
    if(this.$store.getters["user/getUser"].roleId !== Role.ROOT && this.$store.getters["user/getUser"].roleId !== Role.ADMIN && this.$store.getters["user/getUser"].roleId !== Role.USER){
      this.$router.push('/check-in')
    }

  },
  mounted() {
    this.reload();
  },
  methods: {
    reload() {
      this.getAll();
    },

    novaCategoria() {
      this.categoria = {
        descricao: "",
        color: ""
      };
      this.dialog = true;
    },

    editarCategoria(item) {
      this.categoria = { ...item };
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
      this.categoria = {};
    },

    openDialogDelete(item) {
      this.categoria = item;
      this.categoriaExcluir = "";
      this.dialogDelete = true;
    },

    deleteCategoria() {
      if (this.$refs.formDelete.validate()) {
        if (this.categoria.descricao === this.categoriaExcluir) {
          this.loadingSalvar = true;
          CategoriaService.delete(this.categoria.id)
            .then(response => {
              this.$root.showSucesso(response.data);
            })
            .catch(error => {
              if (error.status != 401) {
                this.$root.showErro(error.data);
              }
            })
            .finally(() => {
              this.loadingSalvar = false;
              this.dialogDelete = false;
              this.reload();
            });
        } else {
          this.$root.showErro(
            `O nome da categoria deve ser: <b>${this.categoria.descricao}</b>`
          );
        }
      } else {
        this.$root.showErro("Todos os campos são obrigatórios");
      }
    },

    salvarCategoria() {
      this.loadingSalvar = true;
      CategoriaService.salvar(this.categoria)
        .then(response => {
          this.$root.showSucesso("Operação realizada com sucesso");
          this.categoria = response.data;
          this.closeDialog();
          this.reload();
        })
        .catch(error => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingSalvar = false;
        });
    },

    pesquisar() {
      this.loadingPesquisar = true;
      this.paginar();
    },

    getAll() {
      this.loading = true;
      CategoriaService.getAll()
        .then(response => {
          this.categorias = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
